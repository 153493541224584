import React, { FC, useState } from "react";
import Layout from "../components/Layout/Layout";
import t from "../i18n";
import { useForm } from "react-hook-form";
import { Input, PhoneInputWrapper, TextArea } from "../components/ui/forms/Forms";
import Button from "../components/ui/button/Button";
import { graphql, useStaticQuery } from "gatsby";
import { TypeImage } from "../types";
import Image from "../components/ui/Image";
import HeadMeta from "../components/HeadMeta";
import { sendMail } from "../actions";

type TypeContactDetails = {
    firstName: string
    lastName: string
    email: string
    phone: string
    recaptcha_response: string
    service?: string | number
    message: string
    general?: string
}

interface IContact {
    pageContext: {
        locale: string
    }
}

const DefaultValues = {
    firstName         : "",
    lastName          : "",
    email             : "",
    phone             : "+30",
    recaptcha_response: "",
    message           : ""
}

const Contact: FC<IContact> = ( { pageContext } ) => {
    const { locale: l } = pageContext
    const [ success, setSuccess ] = useState<boolean>( false );
    const [ isSubmitting, setIsSubmitting ] = useState<boolean>( false );
    const { contactPhoto } = useStaticQuery<{ contactPhoto: TypeImage }>( graphql`{
        contactPhoto: file(relativePath: {eq: "705-armchair.jpg"}) {
            relativePath
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
        },
    }
    ` )
    const { register, handleSubmit, setError, getValues, clearErrors, setValue, reset, control, formState: { errors } } = useForm<TypeContactDetails>()
    const onSubmit = handleSubmit( async () => {
        setIsSubmitting( true )
        clearErrors( "general" );
        if (!window.grecaptcha) {
            return
        }
        const token = window.grecaptcha.execute( process.env.GATSBY_GOOGLE_RECAPTCHA, { action: "submit" } );
        let result = await token;
        setValue( "recaptcha_response", result )
        const values = getValues()
        if (process.env.NODE_ENV === 'development') {
            console.log( values )
            reset( DefaultValues )
            setIsSubmitting( false )
            return
        }
        sendMail( "https://modplus.eu/cy-contact/index.php", values,
            () => {
                setSuccess( true )
                reset()
                setIsSubmitting( false )
            },
            ( error ) => {
                setError( "message", {
                    type   : "server-error",
                    message: error.response.data.message
                } );
                setIsSubmitting( false )
            }
        );
    } )
    const successMessage = {
        en: "Thank you for your message! I will get back to you as soon as possible.",
        el: "Ευχαριστούμε για το μήνυμά σας! Θα επικοινωνήσουμε μαζί σας το συντομότερο δυνατό."
    }
    return (
        <Layout noContainer noContentPadding>
            <HeadMeta title={ t( "Contact" ) }/>
            <div className={ "lg:flex--center" }>
                <Image
                    className={ "lg:min-h--main min-h--thumb h--100 w--100" }
                    data={ contactPhoto.childImageSharp.gatsbyImageData }
                    alt={ "705 Armchair" }
                />
                <section className={ "w--100" }>
                    <div className={ "container lg:px--huge px--lg py--xxl" }>
                        <h1>{ t( "Contact" ) }</h1>
                        <p className={ "my--md max-w--60ch" }>{ t( "Feel free to email or call us to talk about your requirements and find out more about what we can do for you." ) }</p>
                        <form className={ "bg--bg p--xl round" } onSubmit={ onSubmit }>
                            <div className={ "lg:flex" }>
                                <Input label={ "First Name" } wrapperClassName={ "lg:mr--md" } errors={ errors } { ...register( "firstName", { required: "First Name is required." } ) } />
                                <Input label={ "Last Name" } errors={ errors } { ...register( "lastName", { required: "Last Name is required." } ) } />
                            </div>
                            <Input label={ "Email" } errors={ errors } { ...register( "email", { required: "Email is required." } ) }/>
                            <PhoneInputWrapper name={ "phone" } label={ "Phone" } errors={ errors } control={ control }/>
                            <TextArea label={ "Message" } errors={ errors } { ...register( "message" ) } rows={ 6 }/>
                            <input hidden type="text" { ...register( "recaptcha_response" ) } />
                            { errors.general && <p className={ "bg--body p--md brd border--red mb--md" }>{ errors.general.message }</p> }
                            { success && <p className={ "bg--body p--md border border--emerald mb--md" }>{ successMessage[l] }</p> }
                            <Button type={ "submit" } className={ "mt--sm" } disabled={ isSubmitting }>{ t( "Send" ) }</Button>
                            <div className="g-recaptcha"
                                 data-sitekey="_reCAPTCHA_site_key_"
                                 data-size="invisible"
                            />
                        </form>
                        { l === "en" ?
                            <p className={ "text--textDimmed mt--sm mb--0 small px--md" }>
                                This site is protected by reCAPTCHA and the Google <a target={ "_blank" } rel={ "noopener noreferrer" } href="https://policies.google.com/privacy">Privacy Policy</a> and <a target={ "_blank" } rel={ "noopener noreferrer" } href="https://policies.google.com/terms">Terms of Service</a> apply.
                            </p>
                            :
                            <p className={ "text--textDimmed mt--sm mb--0 small px--md" }>
                                Αυτό το προστατεύεται από reCAPTCHA και την <a target={ "_blank" } rel={ "noopener noreferrer" } href="https://policies.google.com/privacy?hl=el">Πολιτική Απορρήτου</a> και τους <a target={ "_blank" } rel={ "noopener noreferrer" } href="https://policies.google.com/terms?hl=el">Όρους Παροχής Υπηρεσιών</a> της Google.
                            </p>
                        }
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default Contact
